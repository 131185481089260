import {useState} from "react";
import {useParams} from "react-router-dom";
import {DateTime} from "luxon";

import {useQuery} from "@apollo/client";

import {
    ImageVCentre,
    CustomButton,
    CampaignProgressBar,
    DonationDetail,
    buttonStyles,
    dateToStringNoTime,
} from "gih_web_common";

import logo180x180 from '../../images/logo-180x180.png';

import {GET_CONFIG, GET_CAMPAIGN_BY_ID} from "../../utils/graphQL/queries";


export function EmbedPage() {

    const { id } = useParams();

    const [config, setConfig] = useState(null);
    const [campaign, setCamapaign] = useState(null);

    useQuery(GET_CONFIG, {
        onCompleted: data => setConfig(data.getConfig),
    });

    useQuery(GET_CAMPAIGN_BY_ID, {
        variables: {
            id: id
        },
        onCompleted: data => setCamapaign(data.findCampaignById.campaign),
    });

    return (
        <div className="flex items-center h-[315px] max-w-[560px] bg-white py-2 border border-black">
            <div className="overflow-y-auto max-h-full">
                { campaign && config && <CampaignWidget campaign={campaign} config={config} /> }
            </div>
        </div>
    );
}

function CampaignWidget({campaign, config}) {

    const now = DateTime.now().endOf('minute');

    const handleDonateClick = () => {
        window.open(campaign.dynamicLink, '_blank');
    };

    return (
        <div className="flex flex-col space-y-3 px-3">
            <div className="flex flex-row items-center space-x-3">
                <img src={logo180x180} height={90} width={90} alt="" className="rounded-xl" />
                <div className="text-2xl font-bold mx-auto">{campaign.name}</div>
                <ImageVCentre rmtPrefix={config.imageURLPrefix} rmt={campaign.imageURL} what="primaryImageSmall" />
            </div>
            <div className="space-y-1">
                <div className="flex items-center gap-2">
                    <CampaignProgressBar
                        total={campaign.donations.total}
                        target={campaign.target}
                        supporters={campaign.donations.uniqueDonors}
                    />
                    <CustomButton className={buttonStyles.primaryLgNarrow} onClick={handleDonateClick}>Donate now</CustomButton>
                </div>
                <div className="text-xs text-foreground-secondary">Campaign {campaign.hasEnded ? 'ended' : 'ends'} on {dateToStringNoTime(campaign.endDate)}</div>
            </div>
            { campaign.donations.recent.length > 0 &&
            <div className="space-y-1">
                { campaign.donations.recent.map(donation => (
                <div className="bg-gray-200 rounded-lg py-1 px-2">
                    <DonationDetail key={donation._id} donation={donation} now={now} />
                </div>
                ))}
            </div>
            }
            <div className="text-xs">{campaign.fullDescription}</div>
        </div>
    );
}
