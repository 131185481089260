"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nameStr = nameStr;
function nameStr(user) {
  let s = '';
  if (user.firstName) s += user.firstName;
  if (user.firstName && user.lastName) s += ' ';
  if (user.lastName) s += user.lastName;
  return s;
}