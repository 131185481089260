import {gql} from "@apollo/react-hooks";


export const GET_CONFIG = gql`
    query GetConfig {
      getConfig {
        imageURLPrefix
        videoURLPrefix
        storageBucket
        allowUserInterests
        allowGiving
        donation {
          minimumAmountGBP
          maximumAmountGBP
          smallAmountThresholdGBP
          smallAmountTipOptionsGBP
          defaultTipOptionGBP
          tipPercentOptions
        }
      }
    }
`;

export const GET_CAMPAIGN_BY_ID = gql`
    query FindCampaignById($id: ID!) {
        findCampaignById(id: $id) {
            campaign {
                _id
                type
                workLocation
                name
                imageURL
                videoURL
                hidden
                dynamicLink
                lastNotifiableUpdate
                createdAt
                target
                endDate
                fullDescription
                allowGiftAid
                donations {
                    uniqueDonors
                    total
                    recent {
                        _id
                        name
                        amount
                        giftAidAmount
                        when
                        comment
                    }
                }
            }
        }
    }
`;
