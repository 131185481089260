import React from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import {createRoot} from "react-dom/client";

import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";

import {ErrorWidget, CustomButton, buttonStyles} from "gih_web_common";

import './index.css';

import {EmbedPage} from "./pages/embed/page";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_SERVER
});

const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

function ErrorPage({what}) {

    const navigate = useNavigate();

    return (
        <div className="w-full flex flex-col space-y-4 py-4">
            <div className="flex flex-col mx-auto w-fit justify-center space-y-4">
                <ErrorWidget what={what} />
                <CustomButton className={buttonStyles.primaryLg} onClick={() => navigate('/')}>
                    Take me back to the home page!
                </CustomButton>
            </div>
        </div>
    );
}

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <Site/>
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>
);

function Site() {

    return (
        <>
            <div className="h-full w-full">
                <Routes>
                    <Route path="/campaign/:id" element={<EmbedPage />} />
                    <Route path="*" element={<ErrorPage what="badPath" />}></Route>
                </Routes>
            </div>
        </>
    );
}
