"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.graphQLErrorAsText = graphQLErrorAsText;
exports.isNetworkError = isNetworkError;
require("core-js/modules/es.string.starts-with.js");
require("core-js/modules/es.regexp.to-string.js");
var _errors = require("@apollo/client/errors");
function codeToText(error, userFriendly) {
  return userFriendly && error.extensions.code.startsWith('GIH_') ? "Something went wrong" : error.extensions.code;
}
function isNetworkError(e) {
  return (0, _errors.isApolloError)(e) && e.graphQLErrors.length === 0;
}
function graphQLErrorAsText(e) {
  let userFriendly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if (!(0, _errors.isApolloError)(e)) {
    return e.toString();
  } else if (e.graphQLErrors.length > 0) {
    const detail = e.graphQLErrors.slice(0, 3).map(error => "".concat(codeToText(error, userFriendly), " - ").concat(error.message)).join(', ') + (e.graphQLErrors.length > 3 ? ", ..." : "");
    return detail;
  } else if (e.networkError && e.networkError.result) {
    const detail = e.networkError.result.errors.slice(0, 3).map(error => "".concat(error.extensions.code, " - ").concat(error.message)).join(', ') + (e.networkError.result.errors.length > 3 ? ", ..." : "");
    return "".concat(e.message, " (").concat(e.networkError.name, " ").concat(detail, ")");
  } else {
    return e.toString();
  }
}