"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addressToString = addressToString;
exports.placesToString = placesToString;
require("core-js/modules/web.dom-collections.iterator.js");
function placesToString(places) {
  const qualifiers = new Map();
  places.forEach(place => {
    let names = qualifiers.get(place.qualifier);
    if (names === undefined) {
      names = [];
      qualifiers.set(place.qualifier, names);
    }
    names.push(place.name);
  });
  const groups = [];
  qualifiers.forEach((names, qualifier) => {
    groups.push(names.join(', ') + (qualifier !== null ? " (".concat(qualifier, ")") : ""));
  });
  return groups.join('; ');
}
function addressToString(address) {
  let s = '';
  let append = what => {
    if (what !== null) {
      if (s !== '') {
        s += ', ';
      }
      s += what;
    }
  };
  append(address.line1);
  append(address.line2);
  append(address.streetName);
  append(address.townCity);
  append(address.postcode);
  return s;
}